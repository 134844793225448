export default {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  laptopHeightS: "760px",
  desktop: "2560px",
  mobileHeightShort: "560px",
  mobileHeightMedium: "640px",
  mobileHeightTall: "750px",
  maxInnerWidth: `1240px`,
  navHeight: 86,
  mobileNavHeight: 120,
  sidePlayerWidth: 350,
  sidePlayerWidthSmaller: 300,
  buttonRadius: 6,
};
