import React from "react";
import styled from "styled-components/macro";
import renderHTML from "react-render-html";
import Colors from "../../../consts/Colors";
import Devices from "../../../consts/Devices";
import LoadingPlaceholder from "../../../components/loading-placeholder/LoadingPlaceholder";
import Sizes from "../../../consts/Sizes";

const MixcloudWidget = ({ mixcloudWidgetHtml, setMixcloudWidgetHtml }) => {
  return (
    <Wrapper>
      <CloseButton
        onClick={() => {
          setMixcloudWidgetHtml(null);
        }}
      >
        <span>x</span>
      </CloseButton>
      <WidgetWrapper>{renderHTML(mixcloudWidgetHtml)}</WidgetWrapper>
      <LoadingPlaceholder height={"calc(100% - 21px)"} zIndex={-1} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  bottom: -3px;
  right: 0;
  display: flex;
  flex-direction: column;

  width: 100%;

  @media ${Devices.tablet} {
    width: calc(100% - ${Sizes.sidePlayerWidthSmaller}px);
  }

  @media ${Devices.laptop} and ${Devices.laptopHeightS} {
    width: calc(100% - ${Sizes.sidePlayerWidth}px);
  }
`;

const CloseButton = styled.div`
  margin-right: 10px;
  text-align: center;
  align-self: flex-end;
  background-color: ${Colors.ehfmPrimary(0.6)};
  font-size: 14px;
  padding: 1px 6px 4px 7px;
  color: ${Colors.softWhite};
  cursor: pointer;

  @media ${Devices.tablet} {
    &:hover {
      background-color: ${Colors.softWhite};
      color: ${Colors.ehfmPrimary(0.6)};
      transition: background-color 0.2s, color 0.2s;
    }
  }
`;

const WidgetWrapper = styled.div`
  z-index: 1;
`;

export default MixcloudWidget;
